import { atomAction } from "../../utils/jotai/atomAction";
import { authProviderAtom } from "../login/authProvider.ts";
import { impersonationAtom } from "../user/impersonation.store.ts";
import { apikeyAtom } from "./apikey.store.ts";

export const logoutAction = atomAction(async (get, set) => {
    // XXX: refactor storage to a central location
    sessionStorage.removeItem("firstAccount");

    set(impersonationAtom, undefined);
    set(apikeyAtom, undefined);

    // Need to log out with the auth provider as well to eg. clear social logins
    // const { authProviderAtom } = await import("../login/authProvider.ts");
    const authProvider = get(authProviderAtom);
    // NB: Currently this does nothing, see comment in: auth0Provider -> auth0Logout
    await authProvider.logout({ redirectPath: "" });
});
