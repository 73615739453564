import { getDefaultStore } from "jotai/vanilla";
import { themeStyleElementId, updateCss } from "./cssLoader.ts";
import { themeCssAtom } from "./themeCss.store.ts";
import { getThemeProps_ONCE } from "./useTheme.ts";

let initialized = false;

/** Insert current theme's CSS into the DOM */
export function initTheme() {
    if (initialized) return;
    initialized = true;

    updateThemeCss();

    unsubscribeCssAtom ??= getDefaultStore().sub(themeCssAtom, () => {
        updateThemeCss();
    });
}

/** Update theme CSS (when theme or config changes) */
function updateThemeCss() {
    console.debug("Updating theme CSS...");
    updateCss(getDefaultStore().get(themeCssAtom), themeStyleElementId);

    let faviconEl = document.head.querySelector<HTMLLinkElement>("link[rel='icon']");
    if (!faviconEl) {
        console.debug("Creating favicon element");
        faviconEl = document.createElement("link");
        faviconEl.rel = "icon";
        document.head.appendChild(faviconEl);
    }

    const { favicon } = getThemeProps_ONCE();

    console.debug("Updating favicon: %s", favicon);
    faviconEl.type = "image/svg+xml";
    faviconEl.href = favicon;
}

//#region Hot reload

let unsubscribeCssAtom: (() => void) | undefined;
import.meta.hot?.dispose(() => unsubscribeCssAtom?.());

export function __forceRefreshCss() {
    updateThemeCss();
}

import.meta.hot?.accept((newModule: unknown) => {
    console.debug("Hot reload theme init...");
    (newModule as typeof import("./init")).__forceRefreshCss();
});

//#endregion
