import type Honeybadger from "@honeybadger-io/js";

export const honeybadgerConfig: Parameters<typeof Honeybadger.configure>[0] = {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY as string,
    environment: (import.meta.env.NODE_ENV ?? import.meta.env.MODE) as string,
    revision: import.meta.env.VITE_REVISION as string | undefined,
    filters: ["password", "apikey", "email", "access_token"],
    // debug: true,
};

console.info("Environment: %s, revision: %s", honeybadgerConfig.environment, honeybadgerConfig.revision);
