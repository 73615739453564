import type { AnyRoute, AnyRouter, RegisteredRouter, RoutePaths, UseLinkPropsOptions } from "@tanstack/react-router";
import type { LinkProps, PressEvent } from "react-aria-components";

//#region Generic Action type
export type ActionEventFn = (e: PressEvent) => void | Promise<void>;

export type RouterLinkProps = Parameters<RegisteredRouter["navigate"]>[0];

/**
 * An action to take on clicking an element.
 *
 * Can be a:
 *  - press handler function
 *  - router link via {@link link()}
 *  - URL string
 *  - link properties as {@link LinkProps}
 *
 * @example
 *  action={e => console.log("Clicked", e)}
 *  action={to("/create")}
 *  action={link({ to: "/view/$id", params: { id: "1" } })}
 *  action="https://example.com"
 *  action={{ href: "https://example.com", target: "_blank" }}
 */
export type Action = ActionEventFn | string | RouterLinkProps | LinkProps;

export function actionIsEvent(action: Action | undefined): action is ActionEventFn {
    return typeof action === "function";
}

export function actionIsRouterLink(action: Action | undefined): action is RouterLinkProps {
    return typeof action === "object" && "to" in action;
}

export function actionIsUrl(action: Action | undefined): action is string {
    return typeof action === "string";
}

export function actionIsLinkProps(action: Action | undefined): action is LinkProps {
    return typeof action === "object" && "href" in action;
}
//#endregion

//#region Normalize Action into a function or props
export type NormAction = ActionEventFn | RouterLinkProps | LinkProps;

/** Convert string Actions into {@link LinkProps} */
export function normalizeAction<T extends Action | undefined>(action: T) {
    if (actionIsUrl(action)) {
        const link: LinkProps = { href: action, rel: "noopener" };
        return link;
    }
    return action as Exclude<T, string>;
}
//#endregion

/**
 * Validate a router link as type-safe and return a generic {@link RouterLinkProps}.
 * @example link({ to: "/view/$id", params: { id: "1" } })
 */
export function link<
    /** NB: Generic params copied from {@link UseLinkPropsOptions} */
    TRouter extends AnyRouter = RegisteredRouter,
    TFrom extends RoutePaths<TRouter["routeTree"]> | string = string,
    TTo extends string = "",
    TMaskFrom extends RoutePaths<TRouter["routeTree"]> | string = TFrom,
    TMaskTo extends string = "",
>(options: UseLinkPropsOptions<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>) {
    return options as RouterLinkProps;
}

/**
 * Validate a router link as type-safe and return a generic {@link RouterLinkProps}.
 * @see {@link link()} if the path needs parameters.
 * @example to("/create")
 */
export function to<TRouteTree extends AnyRoute = RegisteredRouter["routeTree"]>(to: RoutePaths<TRouteTree>) {
    return { to } as unknown as RouterLinkProps;
}
