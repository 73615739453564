import type { Auth0Error, WebAuth } from "auth0-js";
import { atom } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { showError } from "../../../dev/errorStream.ts";
import { getFullUrl } from "../../main/urls.ts";
import type { AuthProvider } from "../authProvider.ts";
import { auth0Realm } from "./auth0Config.ts";
import { webAuthAtom } from "./auth0Loader.ts";

export class Auth0Exception extends Error {
    name = "Auth0Exception";

    constructor(public readonly error: Auth0Error) {
        super(getMessage(error));
    }
}

export class Auth0LoginException extends Auth0Exception {
    name = "Auth0LoginException";
}

export class Auth0SignupException extends Auth0Exception {
    name = "Auth0SignupException";
}

function getMessage(error: Auth0Error): string {
    if (error.description === "invalid_domain") {
        return "Email domain is prohibited";
    } else if (typeof error.description === "object") {
        try {
            const originalError = error.original! as object;
            if ("message" in originalError) {
                const parsedErrorData = JSON.parse(originalError.message as string) as Record<string, unknown>;
                if ("message" in parsedErrorData) {
                    return parsedErrorData.message as string;
                }
            }
        } catch (e) {
            showError("Failed to parse error data", e);
        }
        return `Error code: ${error.code}`;
    } else {
        return error.description ?? "Unknown error";
    }
}

export async function auth0Login(webAuth: WebAuth, username: string, password: string, state: string | undefined) {
    return await new Promise<void>((_resolve, reject) => {
        webAuth.login({ username, password, state, realm: auth0Realm }, (err) => {
            if (err) {
                console.error("Auth0 login error", err);
                reject(new Auth0LoginException(err));
            } else {
                // This promise should never resolve, as the login should redirect
                reject(new Error("Login did not redirect"));
            }
        });
    });
}

export interface GuessedAuth0SignupResult {
    Id?: string;
    email?: string;
    emailVerified?: boolean;
}

export async function auth0Signup(webAuth: WebAuth, email: string, password: string) {
    return await new Promise<GuessedAuth0SignupResult>((resolve, reject) => {
        webAuth.signup({ email, password, connection: auth0Realm }, (err, result) => {
            if (err) {
                console.error("Auth0 signup error", err);
                reject(new Auth0SignupException(err));
            } else {
                console.debug("Auth0 signup result", result);
                resolve(result as GuessedAuth0SignupResult);
            }
        });
    });
}

export async function auth0ForgotPassword(webAuth: WebAuth, email: string) {
    return await new Promise<unknown>((resolve, reject) => {
        webAuth.changePassword({ email, connection: auth0Realm }, (err, result) => {
            if (err) {
                console.error("Auth0 forgot password error", err);
                reject(new Auth0Exception(err));
            } else {
                console.debug("Auth0 forgot password result", result);
                resolve(result);
            }
        });
    });
}

export function auth0GoogleLogin(webAuth: WebAuth, state: string | undefined) {
    webAuth.authorize({ connection: "google-oauth2", state });
}

/**
 * NB: Auth0 logout is currently disabled, as it is not necessary, and requires manually configuring valid logout URLs
 * in the Auth0 settings.
 */
const realLogoutEnabled = false;

export function auth0Logout(webAuth: WebAuth, _redirectPath?: string) {
    if (realLogoutEnabled) {
        webAuth.logout({
            returnTo: getFullUrl("/"),
        });
    }
}

export async function auth0ChangePassword(webAuth: WebAuth, email: string) {
    return await new Promise<string>((resolve, _reject) => {
        webAuth.changePassword({ email, connection: auth0Realm }, (err, result: string) => {
            if (err) {
                throw new Auth0Exception(err);
            }
            resolve(result);
        });
    });
}

function encodeState(redirectPath: string | undefined) {
    return redirectPath !== undefined ? JSON.stringify(redirectPath) : undefined;
}

export function decodeState(state: string) {
    try {
        const value = JSON.parse(state);
        invariant(typeof value === "string", "Expected string");
        invariant(value.startsWith("/"), "Expected path");
        return value;
    } catch (e) {
        showError("Failed to parse Auth0 state", e);
        return undefined;
    }
}

export const auth0ProviderAtom = atom((get): AuthProvider => {
    // NB: This is just a promise to load auth0, which will be awaited only when a method is called (for lazy loading)
    const webAuthP = get(webAuthAtom);
    return {
        signup: async ({ username, password }) => {
            const result = await auth0Signup(await webAuthP, username, password);
            return { email: result.email, emailVerified: !!result.emailVerified };
        },
        login: async ({ username, password, redirectPath }) =>
            await auth0Login(await webAuthP, username, password, encodeState(redirectPath)),
        googleAuth: async ({ redirectPath }) => auth0GoogleLogin(await webAuthP, encodeState(redirectPath)),
        forgotPassword: async ({ email }) => await auth0ForgotPassword(await webAuthP, email),
        logout: async ({ redirectPath }) => auth0Logout(await webAuthP, redirectPath),
    };
});

export function exceptionToMessage(error: unknown): string {
    return String(error);
}
