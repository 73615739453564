import { useEffect, useRef } from "react";
import { last } from "remeda";
import { useConfig } from "../../config.ts";

const TITLE_REMOVE_DELAY = 2000;

let titleRemoveTimeout: ReturnType<typeof setTimeout> | undefined;

interface TitleEntry {
    title: string;
}

const titleStack: (TitleEntry | undefined)[] = [];

const PUSH_NEW_INDEX = -1;

function addTitle(title: string, prevIndex: number): number {
    // Keep the initial document title as the fallback in case nothing is mounted with `useTitle`
    if (titleStack.length === 0) {
        titleStack.push({ title: document.title });
    }

    const entry = { title };
    const index = prevIndex !== PUSH_NEW_INDEX ? prevIndex : titleStack.length;
    titleStack[index] = entry;
    updateCurrentTitle();
    return index;
}

function removeTitle(index: number) {
    // Instead of deleting the entry, mark it as undefined (so it stays in the same position case the title changes and the entry will be rewritten)
    titleStack[index] = undefined;

    if (titleRemoveTimeout) {
        clearTimeout(titleRemoveTimeout);
    }
    // Remove the title with a delay to give a new title some time to load before showing a fallback title
    titleRemoveTimeout = setTimeout(() => {
        updateCurrentTitle();
    }, TITLE_REMOVE_DELAY);
}

function updateCurrentTitle() {
    // Clear any undefined entries at the end of the stack
    while (last(titleStack) === undefined) {
        titleStack.pop();
    }

    const newTitle = last(titleStack)!.title;
    if (document.title === newTitle) {
        return;
    }

    document.title = newTitle;
}

/** Set the document title (while the component is mounted) */
export function useTitle(title: string) {
    const { siteName } = useConfig();
    const titleIndexRef = useRef<number>(PUSH_NEW_INDEX);

    useEffect(() => {
        if (title.trim() === "") {
            return;
        }

        // Keep the title index so we can update the title without changing the order
        titleIndexRef.current = addTitle(title + " | " + siteName, titleIndexRef.current);
        return () => {
            removeTitle(titleIndexRef.current);
        };
    }, [title, siteName]);
}
