import { useEffect, type ChangeEvent } from "react";
import type { NumberFieldProps, TextFieldProps } from "react-aria-components";
import { useFormState, type FieldValues, type Path, type RegisterOptions, type UseFormReturn } from "react-hook-form";
import { unsetPropertyCheckProxy } from "../../utils/debug/unsetPropertyCheckProxy";

/**
 * Register a react-aria field component with react-hook-form
 * @returns `{ref, props}` - pass `ref={ref}` to the `Input` and `{...props}` to the Field
 */
export function ariaFieldRegister<T extends FieldValues, TName extends Path<T>>(
    name: TName,
    form: UseFormReturn<T>,
    options?: RegisterOptions<T, TName>,
) {
    const { register, getValues } = form;
    const { name: registerName, onBlur, onChange, ref, required, disabled, ...rest } = register(name, options);

    function onChangeWrapper(value: string) {
        type RhfOnChangeEvent = Parameters<typeof onChange>[0];
        type FakeEvent = ChangeEvent<HTMLInputElement> & RhfOnChangeEvent;

        // NB: `onChange` is called with only the value from react-aria, so we
        // need to wrap it in a fake event object for react-hook-form
        const event = unsetPropertyCheckProxy<FakeEvent>({
            type: "change",
            target: {
                type: "text",
                name: registerName,
                value,
            } satisfies Partial<HTMLInputElement>,
        });
        return onChange(event);
    }

    // NB: can be an empty string or a function
    const optRequired = (options?.required ?? false) !== false;
    const optDisabled = options?.disabled ?? false;

    return {
        ref,
        props: {
            ...rest,
            // NB: Must not use "native" validation since it will break with nested forms (ie. child forms with invalid fields will prevent parent form submission)
            validationBehavior: "aria",
            defaultValue: getValues(name),
            name: registerName,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onBlur,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange: onChangeWrapper,
            isRequired: !!required || optRequired,
            isDisabled: !!disabled || optDisabled,
        } satisfies TextFieldProps | NumberFieldProps,
    } as const;
}

/**
 * Registers and gets the error message for a field.
 *
 * Automatically unregisters the field on unmount.
 *
 * @see {@link ariaFieldRegister} for detailed return value description */
export function useAriaField<T extends FieldValues, TName extends Path<T>>(
    name: TName,
    form: UseFormReturn<T>,
    options?: RegisterOptions<T, TName>,
) {
    const { unregister } = form;
    useEffect(() => {
        // Unregister field on unmount (for optional fields)
        return () => {
            console.debug("Un-registering field %o", name);
            return unregister(name, {
                // NB: Since unregister is also called during hot reload, keep the value.
                // (If this causes any problems, a parameter should be added here to control this.)
                keepValue: true,
                // NB: Removing the default value will cause invalid `undefined` values to be submitted
                keepDefaultValue: true,
            });
        };
    }, [unregister, name]);

    const { control } = form;
    const { errors } = useFormState({ control, name });

    //#endregion Hooks

    const error = errors[name];
    // XXX: some problem in RHF typings requires the `String()` cast
    const errorMessage = error != null ? String(error.message) : undefined;
    // XXX: RHF does not seem to have a field-specific `isValid` field (instead it's a form-wide `isValid`), so emulate it by checking for an error message
    const isInvalid = error != null;

    const obj = ariaFieldRegister(name, form, options);
    return {
        ...obj,
        props: {
            ...obj.props,
            isInvalid,
            errorMessage,
        },
    } as const;
}
