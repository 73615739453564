import { useAtomValue, useStore } from "jotai/react";
import { atom, getDefaultStore } from "jotai/vanilla";
import { optionalConfigAtom, siteThemeAtom } from "../../config.ts";
import { currentColorSchemeAtom } from "../main/ColorSchemeSwitcher.store.ts";
import { themeAtom } from "./theme.store.ts";
import { buildThemeProps } from "./themeBuilder.ts";
import type { ThemeProps, ThemeVarName } from "./types.ts";

//#region Props
const themePropsAtom = atom<ThemeProps>((get) => buildThemeProps(get(themeAtom)));

export function useThemeProps() {
    return useAtomValue(themePropsAtom);
}

export function getThemeProps_ONCE() {
    return getDefaultStore().get(themePropsAtom);
}

const emptyAtom = atom<Partial<ThemeProps>>({});

/** Get theme props only if config is loaded. */
// TODO: Remove this once config is fetched from the backend
export function useOptionalThemeProps() {
    const store = useStore();
    // NB: Do not use `useAtomValue` to prevent flicker when config transitions to loaded
    const hasConfig = store.get(optionalConfigAtom) !== undefined;
    return useAtomValue(hasConfig ? themePropsAtom : emptyAtom);
}

//#endregion

//#region Vars

/** Computed CSS variable values for the theme */
const themeCssStylesAtom = atom((get): CSSStyleDeclaration => {
    // Just subscribe to these atoms, to refresh the CSS contents when they change
    if (import.meta.env.DEV) {
        // Theme contents can be hot-reloaded in development
        get(themeAtom);
    } else {
        get(siteThemeAtom);
    }

    get(currentColorSchemeAtom);

    return window.getComputedStyle(document.documentElement);
});

function getVarValue(vars: CSSStyleDeclaration, name: ThemeVarName): string {
    return vars.getPropertyValue(`--${name}`);
}

export function useThemeVar(name: ThemeVarName) {
    // NB: Always use the default store since the theme is global
    const vars = useAtomValue(themeCssStylesAtom, { store: getDefaultStore() });
    return getVarValue(vars, name);
}

/** Use when you do not want to re-render when the value changes */
export function getThemeVar_ONCE(name: ThemeVarName) {
    const vars = getDefaultStore().get(themeCssStylesAtom);
    return getVarValue(vars, name);
}
//#endregion
