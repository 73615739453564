export class KeyNotFoundError extends Error {
    name = "KeyNotFoundError";

    constructor(key: unknown, objName: string) {
        super(`Key ${JSON.stringify(key)} is not defined in ${objName}`);
    }
}

/** Get a property of an object if it exists, otherwise return `undefined`.
 *
 * Prefer using this instead of the `obj[key]` operator because it does not check the prototype chain.
 */
export function getOwn<T>(obj: T | null | undefined, prop: keyof T | null | undefined) {
    if (prop == null || obj == null) {
        return undefined;
    }

    return Object.prototype.hasOwnProperty.call(obj, prop) ? obj[prop] : undefined;
}

/** Check if an object has a property.
 *
 * Prefer using this instead of the `in` operator because it does not check the prototype chain.
 */
export function hasOwn<T>(obj: T | null | undefined, prop: keyof T | null | undefined) {
    return prop != null && obj != null && Object.prototype.hasOwnProperty.call(obj, prop);
}

/** Get a property of an object if it exists, otherwise throw an error
 *
 * @throws {KeyNotFoundError} if the property does not exist
 * @throws {Error} if the object is not defined
 */
export function mustGetProperty<T>(obj: T | null | undefined, prop: keyof T, objName = "an object"): T[keyof T] {
    if (obj == null) {
        throw new Error(`${objName} is null or undefined`);
    }

    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
        throw new KeyNotFoundError(prop, objName);
    }
    return obj[prop];
}
