import type { Types } from "@honeybadger-io/core";
import { Honeybadger } from "@honeybadger-io/react";
import { isObject } from "@warrenio/utils/isObject";
import { isArray } from "remeda";
import { honeybadgerConfig } from "./honeybadgerConfig.ts";
import { addDevVars } from "./initDev.tsx";

/** NB: Do not use this export directly, instead create wrapper functions to decouple the error reporting provider from the app */
export const honeybadger_INTERNAL = Honeybadger.configure(honeybadgerConfig);

honeybadger_INTERNAL.beforeNotify((notice) => {
    if (!notice) {
        return;
    }

    const causes = [];

    let current: Record<string, unknown> | undefined = notice;
    while (current) {
        causes.push(current);
        current = isObject(current.cause) ? current.cause : undefined;
    }

    // Reverse causes so the root overwrites the child fields
    causes.reverse();

    for (const cause of causes) {
        // Since Honeybadger does not preserve the original error object but instead flattens all the error objects keys into the notice, manually check for `ApiError`'s `meta` field and merge it into the context.
        if (isObject(cause.meta)) {
            notice.context = { ...notice.context, ...cause.meta };
        }

        // Zod errors
        if (isArray(cause.issues)) {
            notice.context.issues = cause.issues;
        }
    }
});

export interface ErrorDetails {
    context?: Record<string, unknown>;
    tags?: string;
}

export function notifyError(error: unknown, details?: ErrorDetails) {
    const realError = error instanceof Error ? error : String(error);
    honeybadger_INTERNAL.notify(realError, details as Partial<Types.Notice>);
}

export function notifyErrorCustom(message: string, details?: ErrorDetails) {
    honeybadger_INTERNAL.notify(message, details as Partial<Types.Notice>);
}

addDevVars({ honeybadger_INTERNAL });
