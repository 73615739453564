import type { ReactNode } from "react";
import { mcn, type BaseProps } from "../utils/baseProps.ts";
import { useTitle } from "../utils/react/useTitle.ts";

export function ViewTitle({ title, children, ...props }: { title: string } & BaseProps & { children?: ReactNode }) {
    useTitle(title);

    return (
        <h1 {...mcn("font-size-heading", props)}>
            {title}
            {children}
        </h1>
    );
}
