import { useCallback, useEffect, useRef, useState } from "react";
import { Overlay } from "react-aria";
import { cn } from "../utils/classNames.ts";
import { showError, showErrorsStream, type ErrorMessage } from "./errorStream.ts";

const colors: Record<ErrorMessage["type"], string> = {
    error: "bg-#ffb3b3",
    warning: "bg-#ffdbb3",
};

const label: Record<ErrorMessage["type"], string> = {
    error: "Error",
    warning: "Warning",
};

const MESSAGE_AUTOCLOSE_MS = 5000;

/** Whole-page overlay.
 * Displays errors raised via eg. {@link showError}.
 */
export function ErrorToaster() {
    const [items, setItems] = useState<ErrorMessage[]>([]);

    const removeItem = useCallback(
        (message: ErrorMessage) => {
            setItems((messages) => messages.filter((m) => m !== message));
        },
        [setItems],
    );

    const addItem = useCallback(
        (message: ErrorMessage) => {
            setItems((messages) => [...messages, message]);
        },
        [setItems],
    );

    useEffect(() => {
        return showErrorsStream.subscribe(addItem);
    }, [addItem]);

    return (
        <Overlay>
            <div className="absolute bottom-2 left-2 right-2 z-20 max-h-60vh overflow-y-auto">
                <div className="flex flex-col gap-2">
                    {items.map((m) => (
                        <MessageBlock key={m.id} item={m} close={() => removeItem(m)} />
                    ))}
                </div>
            </div>
        </Overlay>
    );
}

function MessageBlock({ item: { type, message, args }, close }: { item: ErrorMessage; close: () => void }) {
    /** Auto-close timer */
    const timerRef = useRef<number | null>(null);

    function startTimer() {
        if (timerRef.current == null) {
            timerRef.current = window.setTimeout(close, MESSAGE_AUTOCLOSE_MS);
        }
    }

    function clearTimer() {
        if (timerRef.current != null) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }

    useEffect(() => {
        startTimer();
        return clearTimer;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Do not close while hovering */
    function onMouseEnter() {
        clearTimer();
    }

    function onMouseLeave() {
        startTimer();
    }

    return (
        // biome-ignore lint/a11y/useKeyWithClickEvents: Development-only component
        <div
            className={cn("rounded shadow p-2 overflow-y-auto", colors[type])}
            style={{ maxHeight: "min(25rem, 30vh)" }}
            onClick={close}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <b>{label[type]}:</b> <span className="whitespace-pre">{printfConsoleMessage(message, args)}</span>{" "}
            <span className="text-muted font-size-small text-center">(see console)</span>
        </div>
    );
}

function objectToArgString(obj: unknown) {
    if (typeof obj === "object") {
        return JSON.stringify(obj, null, 2);
    }
    return String(obj);
}

/** Convert console print arguments into a single string */
function printfConsoleMessage(format: string, args: unknown[]): string {
    const argsCopy = [...args];
    const initial = format.replace(/%[so]/g, () => objectToArgString(argsCopy.shift()));
    if (argsCopy.length > 0) {
        return `${initial} ${args.map(objectToArgString).join(" ")}`;
    }
    return initial;
}
