import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import type { ComponentType, ReactNode } from "react";
import { honeybadger_INTERNAL } from "./errorReporting.tsx";

export function ReportingErrorBoundary({
    children,
    ErrorComponent,
}: {
    children: ReactNode;
    ErrorComponent: ComponentType<{ error: unknown }>;
}) {
    return (
        <HoneybadgerErrorBoundary honeybadger={honeybadger_INTERNAL} ErrorComponent={ErrorComponent}>
            {children}
        </HoneybadgerErrorBoundary>
    );
}
