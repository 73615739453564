import { z } from "zod";
import { extendZodWithOpenApi, type ZodOpenApiPathsObject } from "zod-openapi";
import { privateApi, successResponse } from "../util.ts";
import { editableFields } from "./billing.editableFields.ts";
import { LinkProcessorId } from "./billing.ts";
import { broken_boolean, int } from "./common.ts";
import { Location } from "./locations.js";

extendZodWithOpenApi(z);

//#region VM config
const VmSizeOs = z.enum(["win", "other"]).openapi({ ref: "VmSizeOs" });

const VmSize = z
    .object({
        cpu: int,
        ram: z.number(),
        ssd: z.number(),
        default: z.array(VmSizeOs),
        disabled: z.array(VmSizeOs).optional(),
        os: z.array(VmSizeOs),
        notice: z.string().optional(),
    })
    .refine((v) => !v.disabled?.some((os) => !v.os.includes(os)), {
        message: "Disabled OS must be in the list of OS",
    })
    .refine((v) => !v.default.some((os) => !v.os.includes(os)), {
        message: "Default OS must be in the list of OS",
    })
    .refine((v) => !v.default.some((os) => v.disabled?.includes(os)), {
        message: "OS cannot be default if it is disabled",
    })
    .openapi({ ref: "VmSize" });
//#endregion

//#region Chat config
const IntercomConfig = z
    .object({
        type: z.literal("intercom"),
        intercom_app_id: z.string(),
    })
    .openapi({ ref: "IntercomConfig" });

const QiscusConfig = z
    .object({
        type: z.literal("qiscus"),
        qiscus_client_key: z.string(),
        qiscus_channel_id: z.string(),
    })
    .openapi({ ref: "QiscusConfig" });

const BotikaConfig = z
    .object({
        type: z.literal("botika"),
        botika_client_key: z.string(),
    })
    .openapi({ ref: "BotikaConfig" });

const OncehubConfig = z
    .object({
        type: z.literal("oncehub"),
        oncehub_website_id: z.string(),
        oncehub_bot_id: z.string(),
    })
    .openapi({ ref: "OncehubConfig" });

const ChatConfig = z
    .union([
        z.discriminatedUnion("type", [IntercomConfig, QiscusConfig, BotikaConfig, OncehubConfig]),
        // Empty object currently if disabled, perhaps should be `null`...
        z.object({}),
    ])
    .openapi({ ref: "ChatConfig" });
//#endregion

//#region Payment config
const ProcessorConfigBase = z
    .object({
        type: z.string(),
        keyword: z.string(),
        name: z.string(),
        disable_add: broken_boolean.optional(),
    })
    .openapi({ ref: "ProcessorConfigBase" });

const ProcessorServiceFee = z
    .object({
        flat_rate: z.number(),
        percentage: z.number(),
    })
    .openapi({ ref: "ProcessorServiceFee" });

const ProcessorCurrencyFee = z
    .object({
        to_currency: z.string(),
        exchange_rate: z.number(),
        for_country: z
            .string()
            .optional()
            .describe("If set, the exchange rate will be applied only to the specified country."),
    })
    .openapi({ ref: "ProcessorCurrencyFee" });

const ProcessorFeeConfig = z
    .object({
        service_fee: ProcessorServiceFee.optional(),
        charge_currency_conversion: ProcessorCurrencyFee.optional(),
    })
    .openapi({ ref: "ProcessorFeeConfig" });

const CardConfigBase = ProcessorConfigBase.extend(ProcessorFeeConfig.shape)
    .extend({
        type: z.literal("creditcard"),
        public_key: z.string(),
        verification_amount: z.number(),
    })
    .openapi({ ref: "CardConfigBase" });

const StripeCardConfig = CardConfigBase.extend({
    keyword: z.literal("stripe_creditcard"),

    service_fee: ProcessorServiceFee.optional(),

    show_postalcode_input: broken_boolean.optional(),
}).openapi({ ref: "StripeCardConfig" });

const OmiseCreditCardConfig = CardConfigBase.extend({
    keyword: z.literal("omise_creditcard"),

    service_fee: ProcessorServiceFee.optional(),

    use_3ds_verification: broken_boolean.optional(),
}).openapi({ ref: "OmiseCreditCardConfig" });

const MethodConfig = z
    .object({
        key: z.string(),
        name: z.string(),
    })
    .openapi({ ref: "MethodConfig" });

const HybridConfig = ProcessorConfigBase.extend({
    type: z.literal("hybrid"),
    keyword: z.literal("adyen"),

    methods: z.array(MethodConfig),
}).openapi({ ref: "HybridConfig" });

const InvoiceConfig = ProcessorConfigBase.extend({
    type: z.literal("invoice"),
    keyword: z.literal("invoice"),
}).openapi({ ref: "InvoiceConfig" });

const LinkConfigBase = ProcessorConfigBase.extend(ProcessorFeeConfig.shape)
    .extend({
        type: z.literal("link"),
        keyword: LinkProcessorId,
        methods: z.array(MethodConfig),
    })
    .openapi({ ref: "LinkConfigBase" });

const DuitkuConfig = LinkConfigBase.extend({
    keyword: z.literal("duitku"),

    public_key: z.string(),
}).openapi({ ref: "DuitkuConfig" });

const StripeWalletConfig = LinkConfigBase.extend({
    keyword: z.literal("stripe_wallet"),

    public_key: z.string(),
}).openapi({ ref: "StripeWalletConfig" });

const OmiseConfig = LinkConfigBase.extend({
    keyword: z.literal("omise"),

    public_key: z.string(),
}).openapi({ ref: "OmiseConfig" });

const SslcommerzConfig = LinkConfigBase.extend({
    keyword: z.literal("sslcommerz"),

    store_id: z.string(),

    is_test: z.unknown().optional(),
    accept_high_risk: z.unknown().optional(),
}).openapi({ ref: "SslcommerzConfig" });

const ClicToPayConfig = LinkConfigBase.extend({
    keyword: z.literal("clictopay"),

    username: z.string(),
    is_test: z.unknown().optional(),
}).openapi({ ref: "ClicToPayConfig" });

const ProcessorConfig = z
    .discriminatedUnion("keyword", [
        StripeCardConfig,
        OmiseCreditCardConfig,
        SslcommerzConfig,
        ClicToPayConfig,
        StripeWalletConfig,
        DuitkuConfig,
        OmiseConfig,
        InvoiceConfig,
        HybridConfig,
    ])
    .openapi({ ref: "ProcessorConfig" });
//#endregion

//#region Billing
const EditableFieldName = z.enum(editableFields).openapi({ ref: "EditableFieldName" });

const BillingAccountField = z
    .object({
        field: EditableFieldName,
        required: z.boolean(),
        visible: z.boolean().optional(),
        validator: z
            .object({
                type: z.string(),
                group: z.string(),
            })
            .optional(),
    })
    .openapi({ ref: "BillingAccountField" });
//#endregion

export const MainConfig = z
    .object({
        // !!!!!!! IMPORTANT !!!!!!!
        // NB: All new/added fields MUST always be `.optional()` for backwards compatibility.
        // (and make them `.nullable()` if they are actually optional)
        // Also add them to `defaultConfig` in the frontend.
        // !!!!!!! IMPORTANT !!!!!!!

        siteName: z.string(),
        siteTheme: z.string(),
        siteCountry: z.string().length(2),
        siteCurrency: z.string().length(3),
        siteLocale: z.string(),
        siteLanguage: z.string(),

        apiDocUrl: z.string().url(),

        // XXX(WRN-318): Make this non-optional
        locations: z.array(Location).optional(),

        // Branding (move to theme?)
        loginTitle: z.string().optional(),
        loginSubtitle: z.string().optional(),

        // Enabled features
        vmsEnabled: z.boolean().optional(),
        vmAppsEnabled: z.boolean(),
        ipsEnabled: z.boolean().optional(),
        privateNetworksEnabled: z.boolean(),
        loadBalancerEnabled: z.boolean(),
        storageEnabled: z.boolean(),
        servicesEnabled: z.boolean(),
        kubernetesEnabled: z.boolean(),
        bareMetalEnabled: z.boolean().optional(),

        // Login & auth
        auth0: z.object({
            domain: z.string(),
            clientID: z.string(),
        }),
        mfaAvailable: z.boolean(),

        // Promotions
        recurringPaymentsEnabled: z.boolean(),
        firstAccountCredit: z.number(),
        referralCodeEnabled: z.boolean(),
        referralCreditAmounts: z.object({
            receiver_credit: z.number(),
            promoter_credit: z.number(),
            referral_credit_receiver_precondition_topup_sum: z.number().optional(),
        }),

        // Payment
        billingEmail: z.string().email(),
        limitedBillingAccountConfig: z.object({
            vcpu: z.number(),
            ram_mb: z.number(),
        }),
        minimumTopupAmount: z.number(),
        allowDebt: z.boolean(),
        paymentProcessors: z.array(ProcessorConfig),
        billingAccountFields: z.array(BillingAccountField),

        // Misc
        predefinedPackages: z.array(VmSize),
        s3ForcePathStyle: z.boolean(),

        // Support
        supportEmail: z.string().email(),
        supportUrl: z.union([z.string().url(), z.literal("")]),
        chatConfig: ChatConfig,

        // !!!!!!! IMPORTANT !!!!!!!
        // ^^^^^^^ Read the comment above ^^^^^^^^
    })
    .passthrough()
    .refine((v) => !v.kubernetesEnabled || v.servicesEnabled, {
        message: "Kubernetes requires services to be enabled",
    })
    .openapi({ ref: "MainConfig" });

export const configPaths: ZodOpenApiPathsObject = {
    "/config/ui/config": {
        get: {
            summary: `Get UI config ${privateApi}`,
            tags: ["config"],
            responses: {
                ...successResponse(MainConfig),
            },
        },
    },
};
