import C from "./Loading.module.css";

import { Link } from "@tanstack/react-router";
import { Suspense, useEffect, useMemo, useState, type ReactNode } from "react";
import { getResourceCreateLink } from "../../modules/api/resourceCreateLinks.ts";
import { getResourceIconClass, getResourceTypeName, type ResourceType } from "../../modules/api/resourceTypes.tsx";
import { useOptionalThemeProps } from "../../modules/theme/useTheme.ts";
import { cn } from "../../utils/classNames";
import { cleanTimeout } from "../../utils/cleanTimeout";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export type LoadingIcon = "default" | "none" | "logo";

export interface LoadingProps {
    delay?: number;
    icon?: LoadingIcon;
    /** Use white background for progress bar */
    white?: boolean;
    /** Placeholder will still take up space even when not yet visible */
    fillSpace?: boolean;
}

export function Loading({ delay = 500, icon, white, fillSpace }: LoadingProps) {
    const [visible, setVisible] = useState(false);
    const { favicon } = useOptionalThemeProps();

    // Delay showing the loading spinner a bit to avoid flicker
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => cleanTimeout(() => setVisible(true), delay), []);

    return (
        <div
            className={cn(
                C.Loading,
                icon === "none" && C.Content,
                white && C.White,
                !visible && (fillSpace ? "important:invisible" : "important:hidden"),
            )}
        >
            <div className={C.holder}>
                {icon !== "none" &&
                    (icon === "logo" && favicon ? (
                        <img alt="Loading" src={favicon} className={C.Splash} />
                    ) : (
                        <MaskIcon className="jp-cactus-icon size-5rem color-grey-5 mb-3" />
                    ))}
                <div className={C.border}>
                    {/* Since this component should be extra lean due to being the first one to load, do not use react-aria's `ProgressBar` to reduce initial bundle size. */}
                    <div
                        /* biome-ignore lint/a11y/useAriaPropsForRole: This is an indeterminate progress bar so ARIA value props are not needed */
                        role="progressbar"
                        aria-label="Loading"
                        className={C.progress}
                    >
                        <div className={C.bar}>
                            <div className={C.fill} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export interface LoadingProgressProps {
    delay?: number;
    /** Placeholder will still take up space even when not yet visible */
    fillSpace?: boolean;
    name: string;
    type: ResourceType;
    lifeTime: DOMHighResTimeStamp;
}

export function LoadingProgress({ delay = 0, name, type, lifeTime, fillSpace }: LoadingProgressProps) {
    const [visible, setVisible] = useState(false);

    // Delay showing the loading spinner a bit to avoid flicker
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => cleanTimeout(() => setVisible(true), delay), []);

    const [currentPercentage, setCurrentPercentage] = useState(0);

    const startTime = useMemo(() => performance.now(), []);

    useEffect(() => {
        if (startTime) {
            const updatesPerSecond = 5;
            const updateInterval = 1000 / updatesPerSecond;
            const interval = setInterval(() => {
                setCurrentPercentage((prev) => {
                    const now = performance.now();
                    const percentage = ((now - startTime) / lifeTime) * 100;
                    if (prev >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return percentage;
                });
            }, updateInterval);
            return () => clearInterval(interval);
        }
    }, [lifeTime, startTime]);

    let createLink;
    const linkProps = getResourceCreateLink(type);
    if (linkProps) {
        createLink = (
            <Link {...linkProps} className="text-primary">
                <b>Create another {getResourceTypeName(type)}</b>
            </Link>
        );
    }

    return (
        <div
            className={cn(
                C.Loading,
                C.ProgressBar,
                C.Content,
                !visible && (fillSpace ? "important:invisible" : "important:hidden"),
            )}
        >
            <div className={C.holder}>
                <div className={cn(C.Icon, "mb-3")}>
                    <MaskIcon className={cn(getResourceIconClass(type), "size-2.5rem color-primary")} />
                </div>

                <div className="font-size-small pb-3">
                    Creating ({name})... {currentPercentage.toFixed(0)}%
                </div>

                <div className={C.border}>
                    {/* Since this component should be extra lean due to being the first one to load, do not use react-aria's `ProgressBar` to reduce initial bundle size. */}
                    <div
                        /* biome-ignore lint/a11y/useAriaPropsForRole: This is an indeterminate progress bar so ARIA value props are not needed */
                        role="progressbar"
                        aria-label="Loading"
                        className={C.progress}
                    >
                        <div className={C.bar}>
                            <div className={C.fill} style={{ width: `${currentPercentage}%` }} />
                        </div>
                    </div>
                </div>

                <div className="font-size-small color-muted pt-3 pb-3">While the job is in progress you can:</div>

                {createLink}
            </div>
        </div>
    );
}

export function PageLoadingSuspense({ children }: { children: ReactNode }) {
    return <Suspense fallback={<Loading icon="logo" />}>{children}</Suspense>;
}

export function LoadingSuspense({ children }: { children: ReactNode }) {
    return <Suspense fallback={<Loading />}>{children}</Suspense>;
}

export function ContentLoadingSuspense({ children }: { children: ReactNode }) {
    return <Suspense fallback={<Loading icon="none" />}>{children}</Suspense>;
}

/** Used for the entire right-hand content pane */
export function MainLayoutContentLoadingSuspense({ children }: { children: ReactNode }) {
    return (
        <Suspense
            fallback={
                <Loading
                    icon="none"
                    // Use a higher timeout here since the contents are the slowest to load and we do not always want to show the loading spinner
                    delay={2000}
                />
            }
        >
            {children}
        </Suspense>
    );
}
