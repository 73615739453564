import type { ZodOpenApiObject, ZodOpenApiPathsObject } from "zod-openapi";
import { accessPaths } from "./spec/access.ts";
import { BillingAdditionalData, allLinkParamSchemas, billingPaths } from "./spec/billing.ts";
import { campaignsPaths } from "./spec/campaigns.ts";
import { configPaths } from "./spec/config.ts";
import { hostPaths } from "./spec/hosts.ts";
import { locationPaths } from "./spec/locations.ts";
import { metalAdminPaths, metalPaths, metalSchemas } from "./spec/metal.ts";
import { networkPaths } from "./spec/network.ts";
import { hostPoolPaths } from "./spec/pools.ts";
import { pricingPaths } from "./spec/pricing.ts";
import { referralPaths } from "./spec/referral.ts";
import { servicePaths } from "./spec/services.ts";
import { storagePaths } from "./spec/storage.ts";
import { trackingPaths } from "./spec/tracking.ts";
import { userPaths } from "./spec/user.ts";
import { vmPaths } from "./spec/vm.ts";
import { ind } from "./util.ts";

export const paths = {
    ...locationPaths,
    ...vmPaths,
    ...servicePaths,
    ...networkPaths,
    ...pricingPaths,
    ...storagePaths,
    ...accessPaths,
    ...billingPaths,
    ...hostPaths,
    ...hostPoolPaths,
    ...userPaths,
    ...configPaths,
    ...campaignsPaths,
    ...referralPaths,
    ...trackingPaths,
    ...metalPaths,
    ...metalAdminPaths,
} satisfies ZodOpenApiPathsObject;

const siteName = "Warren.io";
const description = ind`
# Introduction

Welcome to the ${siteName} API!
You can use our API to eg. create and manage compute and storage resources like Virtual Machines and Object Storage buckets.
`;

export const api: ZodOpenApiObject = {
    openapi: "3.1.0",
    info: {
        title: `${siteName} API`,
        version: "1.0.0",
        "x-logo": {
            url: "https://api.warren.io/images/logo.svg",
            href: "http://warren.io/",
            altText: "Warren logo",
        },
        description,
    },
    servers: [{ url: "https://api.warren.io/v1" }],
    security: [{ apikey: [] }],
    components: {
        securitySchemes: {
            apikey: {
                type: "apiKey",
                in: "header",
                name: "apikey",
                // description: "API key",
            },
        },
        // Extra types that are not referenced by any paths/responses
        schemas: {
            BillingAdditionalData,
            ...allLinkParamSchemas,
            ...metalSchemas,
        },
    },
    paths,
    tags: [
        {
            name: "location",
            "x-displayName": "Locations",
            description:
                "Most resources are located in a specific data center or region. To create or access them, supply a location slug in the URL path.",
        },
        {
            name: "vm",
            description: "Virtual Machines",
            "x-displayName": "VMs",
        },
        {
            name: "host_pool",
            "x-displayName": "Host Pools",
        },
        {
            name: "vpc",
            "x-displayName": "Private Networks",
        },
        {
            name: "ip_address",
            "x-displayName": "Floating IPs",
        },
        {
            name: "load_balancer",
            "x-displayName": "Load Balancers",
        },
        {
            name: "storage",
            "x-displayName": "Storage",
        },
        {
            name: "token",
            description: "API Tokens",
            "x-displayName": "Tokens",
        },
        {
            name: "delegation",
            description: "Access Delegation",
            "x-displayName": "Delegation",
        },
        {
            name: "pricing",
            "x-displayName": "Pricing",
        },
        {
            name: "billing",
            "x-displayName": "Billing",
        },

        {
            name: "impersonation",
            "x-displayName": "Impersonation",
        },
        {
            name: "user",
            "x-displayName": "User",
        },
        {
            name: "config",
            "x-displayName": "Configuration",
        },
        {
            name: "services",
            description: "Managed Services",
            "x-displayName": "Services",
        },
        {
            name: "metal",
            "x-displayName": "Bare Metal",
        },
        {
            name: "admin_metal",
            "x-displayName": "Admin: Bare Metal",
        },
        {
            name: "admin_campaigns",
            "x-displayName": "Admin: Campaigns",
        },
        {
            name: "admin_referral",
            "x-displayName": "Admin: Referral",
        },
        {
            name: "admin_tracking",
            "x-displayName": "Admin: Tracking",
        },
    ],
};
