import type { PartialDeep } from "type-fest";

const proxyHandler: ProxyHandler<object> = {
    get(target, prop) {
        if (prop in target) {
            const value = (target as Record<typeof prop, unknown>)[prop];
            // Recursively wrap objects
            if (typeof value === "object" && value != null) {
                return unsetPropertyCheckProxy(value);
            }
            return value;
        }
        console.error(`Property ${String(prop)} was expected but was not found on %o`, target);
        return undefined;
    },
};

const throwingProxyHandler: ProxyHandler<object> = {
    get(target, prop) {
        if (prop in target) {
            const value = (target as Record<typeof prop, unknown>)[prop];
            // Recursively wrap objects
            if (typeof value === "object" && value != null) {
                return unsetPropertyThrowProxy(value);
            }
            return value;
        }
        throw new Error(`Property ${String(prop)} was expected but was not found on %o`);
    },
};

/** Warns about any object field accesses that are undefined. */
export function unsetPropertyCheckProxy<T extends object>(obj: PartialDeep<T>): T {
    if (!import.meta.env.DEV) {
        return obj as T;
    }

    return new Proxy(obj as T, proxyHandler as ProxyHandler<T>);
}

/** Throws an error about any object field accesses that are undefined. */
export function unsetPropertyThrowProxy<T extends object>(obj: PartialDeep<T>): T {
    if (!import.meta.env.DEV) {
        return obj as T;
    }

    return new Proxy(obj as T, throwingProxyHandler as ProxyHandler<T>);
}
