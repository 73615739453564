import isChromatic from "chromatic/isChromatic";
import invariant from "tiny-invariant";
import { getBasedFrom } from "../../utils/paths.ts";

const BASE_URL = import.meta.env.BASE_URL;

/** base path without the trailing slash (since it will be always added in `getRelativeUrl`) */
export const basepath = BASE_URL === "/" ? "" : BASE_URL || "/app";

if (basepath.endsWith("/")) {
    console.error("basepath %o should not end with a slash", basepath);
}

/** NB: Chromatic uses a different hostname for every snapshot, so we need to use a constant URL so the snapshots would not constantly change */
export function getVisibleSiteBaseUrl() {
    return isChromatic() ? "https://example.com" : window.location.origin;
}

export function getFullUrl(path: string) {
    return `${window.location.origin}${getFullPath(path)}`;
}

/** @returns Path with the base path included */
export function getFullPath(path: string) {
    invariant(path.startsWith("/"), "Path must start with a slash");
    return `${basepath}${path}`;
}

/** @returns Current location (relative from base path) */
export function getCurrentPath() {
    return getBasedFrom(basepath, location.pathname);
}
