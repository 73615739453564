import { atomWithLocalStorage } from "../../utils/jotai/atomStorage.ts";

export interface ImpersonationInfo {
    /** API token to use */
    token: string;
    /** Name to display in "Acting as ..." banner */
    access_owner: string;
    returnPath?: string;
}

// NB: Can not place this in session storage since new tabs will not inherit it (eg. terminal).
// This means that all the tabs will always share the same impersonation state.
// See (Firefox): [818389 - sessionStorage variables not being copied to new tab](https://bugzilla.mozilla.org/show_bug.cgi?id=818389)
export const impersonationAtom = atomWithLocalStorage<ImpersonationInfo | undefined>("impersonation", undefined);
