import sleep from "@warrenio/utils/promise/sleep";
import type { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import { jsonLocalStorage } from "../../utils/jotai/atomStorage";
import type { MockDbOptions } from "../mockDbVariants.ts";
import type { MockDbInitOptions } from "./mswOptions.ts";

/** Promise with the current MSW initialization progress. `undefined` if not initialized at all. */
let mswPromise: Promise<void> | undefined;

/** Cached value of whether MSW is enabled. `undefined` if not loaded yet. */
let isMswEnabled: boolean | undefined;

/** For use in stories and tests. */
let isForceEnabled = false;

const storage = jsonLocalStorage() as SyncStorage<boolean>;
const key = "mswEnabled";

/** MSW is not supported on production */
const isProd = import.meta.env.PROD;

/** Notify that something is waiting for our initialization (so we should immediately proceed with loading). */
let resolveWaitingForInitPromise: (() => void) | undefined;
const waitingForInitPromise = new Promise<void>((resolve) => {
    resolveWaitingForInitPromise = resolve;
});
const initDelayMs = 500;

export function isSupported() {
    return !isProd;
}

export function isMockApiEnabled() {
    if (isProd) return false;

    if (isForceEnabled) return true;

    if (isMswEnabled === undefined) {
        isMswEnabled = storage.getItem(key, false);
    }
    return isMswEnabled;
}

export function setEnabled(enabled: boolean) {
    if (isProd) return;

    if (isForceEnabled) {
        console.warn("MSW is force enabled, setting should not be changed");
        return;
    }

    if (isMswEnabled === enabled) return;

    isMswEnabled = enabled;
    storage.setItem(key, enabled);

    // Re-initialize if we were already initialized, or start initialization if we are now enabled
    if (mswPromise != null || enabled) {
        void registerMsw(enabled);
    }
}

export function setForceEnabled() {
    if (!isForceEnabled) console.debug("Force enable MSW");

    isForceEnabled = true;
}

/** Wait for MSW initialization to finish (eg. before making API requests) */
export async function waitForMswInit() {
    if (isProd) return;

    resolveWaitingForInitPromise?.();

    if (mswPromise) {
        await mswPromise;
    }
}

/** Lazily load MSW in the background. */
function beginMswInit(preInit?: () => Promise<void>) {
    if (isProd) return;

    if (!isMockApiEnabled()) return;

    void registerMsw(true, preInit);
}

/** Load MSW and initialize the mock DB. */
export function beginMockDbInit(options: MockDbInitOptions) {
    if (isProd) return;

    beginMswInit(async () => {
        const db = await import("../db");
        db.initMockDb(options);
    });
}

export async function getMockDbOptions(): Promise<MockDbOptions> {
    if (isProd) return {};

    const db = await import("../db");
    return db.getMockDb().options;
}

export async function resetMockDb(options: MockDbOptions) {
    if (isProd) return;

    const db = await import("../db");
    db.resetDbWithOptions(options);
}

/**
 * Start the MSW initialization process.
 *
 * {@link mswPromise} is used as a lock to make sure that only one initialization is running at a time.
 */
function registerMsw(enabled: boolean, preInit?: () => Promise<void>) {
    const prevMswPromise = mswPromise;
    return (mswPromise = (async () => {
        // Wait for previous initialization to finish
        if (prevMswPromise) {
            await prevMswPromise;
        } else {
            // Delay the initialization of MSW until some time has passed or the fetch client is waiting for it.
            // Initializing it early causes it to intercept static resource requests (eg. JS), which slows down the page load.
            await Promise.race([sleep(initDelayMs), waitingForInitPromise]);
        }

        const [register] = await Promise.all([import("./register"), preInit?.()]);
        try {
            await register.registerMsw(enabled);
        } catch (e) {
            console.error(e, "Failed to register mock service worker");
        }
    })());
}
